import { useState } from 'react';

import { useQuery } from '@apollo/client';
import { useRouter } from 'next/router';

import { PageLoader } from '@crehana/compass.ui/v2';
import { useUITheme } from '@crehana/web/Layouts/UITheme';

import { ErrorOverlay } from '@/views/Auth/components';
import ME_QUERY from '@/views/Auth/graphql/AuthPagesMeQuery.graphql';
import { AuthPagesMeQuery } from '@/views/Auth/graphql/types/AuthPagesMeQuery';
import routes from '@/views/Auth/routes';

function withAuth<TProps>(
  Comp: React.FC<React.PropsWithChildren<TProps>>,
  { authenticated = true }: { authenticated?: boolean },
) {
  // TODO: talk with the code owner to fix this code so we can enable this rule again
  // eslint-disable-next-line react/display-name
  return (props: TProps) => {
    const { isDark } = useUITheme();
    const [showView, setShowView] = useState(false);
    const router = useRouter();
    const { loading, error, data, refetch } = useQuery<AuthPagesMeQuery>(
      ME_QUERY,
      {
        fetchPolicy: 'network-only',
        notifyOnNetworkStatusChange: true,
        onCompleted: dataCb => {
          const firstCase = dataCb.me && !authenticated;
          const secondCase = !dataCb.me && authenticated;

          if (firstCase || secondCase) {
            let destination = secondCase ? routes.loginPath : routes.homeUser;

            if (dataCb?.me?.hasOrganization && dataCb?.me?.organization?.slug) {
              destination = secondCase
                ? routes.b2bOrgLoginPath(dataCb?.me?.organization?.slug)
                : routes.b2bOrgHomeUser(dataCb?.me?.organization?.slug);
            }

            // next_url or next has priority over any other destination url
            const nextUrlQueryParam = router.query.next_url as
              | string
              | undefined;
            const nextQueryParam = router.query.next as string | undefined;
            const nextUrl = nextUrlQueryParam || nextQueryParam;
            destination = nextUrl
              ? window.decodeURIComponent(nextUrl)
              : destination;

            window.location.replace(destination);
          } else {
            setShowView(true);
          }
        },
        onError: () => {
          setShowView(true);
        },
      },
    );

    if (loading || !showView) return <PageLoader />;
    if (error)
      return <ErrorOverlay isDark={isDark} onTryAgain={() => refetch()} />;

    return <Comp {...props} me={data?.me} />;
  };
}

export default withAuth;
